<template>
  <CRow style="margin: 0 auto; margin-top: 15%;">
    <CCol sm="3"></CCol>
		<CCol sm="6">
			<CCard>
				<CCardHeader>
					<strong>PENCARIAN</strong> JADWAL KUNJUNGAN
				</CCardHeader>
				<CCardBody class="text-center">
          <CInput
            style="text-align: center;"
						type="text"
            ref="urls"
            id="urls"
						label="Scan QRCode Anda."
						:value.sync="url"
            @keyup.enter="inputEnter(url)"
					>
            <template #append>
              <CButton size="sm" color="success" @click="search(url)"><CIcon name="cil-globe-alt"/></CButton>
            </template>
          </CInput>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol sm="3"></CCol>
  </CRow>
</template>
<script>
export default {
  name: 'QRScanner',
  data () {
		return {
      url: ''
    }
  },
  methods: {
    toast(message, type) {
      this.$toast.open({
        message: message,
        type: type, // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 3000,
        dismissible: true
      })
    },
    inputEnter(url) {
      this.search(url);
    },
    search(url) {
      if (url === '' || url.length < 10 || url.split('/')[6] === 'undefined') {
        this.toast('Data QR Code Kosong?', 'error');
      } else {
        const goTo = '/approval/visit-request/' + url.split('/')[6]
        this.$router.push({ path: goTo });
      }
    }
  },
  mounted() {
    document.getElementById('urls').focus();
  }
}
</script>